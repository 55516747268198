import './bootstrap';

import $ from 'jquery';
window.$ = window.jQuery = $;

import jQueryBridget from 'jquery-bridget';


import imagesLoaded from 'imagesloaded';
import BlurryImageLoad from './blurryload';
import Flickity from 'flickity';
import videojs from 'video.js';
import SimpleParallax from "simple-parallax-js/vanilla";
import 'jquery-touchswipe';
import Cookies from 'js-cookie';

imagesLoaded.makeJQueryPlugin($);
jQueryBridget( 'flickity', Flickity, $ );

var breakpoint = 781;
var ismobile = false;
var scrollLastTop = 0;
var scrollUpDistance = 0;
var scrollThreshold = 100;
var parallaxes = [];

const blurryImageLoad = new BlurryImageLoad();


if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	ismobile = true;
}


Number.prototype.clamp = function(min, max) {
  return Math.min(Math.max(this, min), max);
};

function replaceAll(find, replace, str) {
  return str.replace(new RegExp(find, 'g'), replace);
}

function freezeScroll(){

	var html = $('html');

	html.css('overflow', 'hidden');
	
	if(ismobile == true){
		$('#wrapper').on('touchmove', function(e){
			e.preventDefault();
		})
	}

}


function unfreezeScroll(){

	var html = $('html');

	html.removeAttr('style');
	
	if(ismobile == true){
		$('#wrapper').off('touchmove');
	}

}

function getLastPath(string){

   if(string){

      return string.split("/").pop();

   }else{

      return window.location.pathname.split("/").pop();   

   }

   

}


function isResponsive(){

   return $(window).width() <= breakpoint;

}

function setResponsive(){

	if( isResponsive() ){


	}else{


	}

	
	parallaxes.forEach( element => {

		element.destroy();
		element = null;

	});

	
	var images = document.querySelectorAll('.parallax');
	var instance = new SimpleParallax(images, {
		orientation: 'down',
		scale: 1.5
	});

	parallaxes.push( instance );
	
	updateSlideshows();

}


function openMenu(){

	$('header').addClass('open');

	$('#blender').fadeIn(400);

}

function closeMenu(){

	$('header').removeClass('open');

	$('#blender').fadeOut(400);

}




function scrollToTop(){

	$('html,body').stop().animate({

		scrollTop:'0px'

	},400);

}


function initVideos(){

    const videoElements = document.querySelectorAll('.video-js');
    videoElements.forEach(videoElement => {
        if (!videoElement.player) {
            videojs(videoElement, {
                controls: true,
                autoplay: false,
                liveui: false,
                controlBar:{
                    liveDisplay: false,
                    pictureInPictureToggle: false,
                    seekToLive: false,   
                },
                preload: 'auto'
            });
        }
    });

}

function destroyVideos() {
    const videoElements = document.querySelectorAll('.video-js');
    videoElements.forEach(videoElement => {
        const player = videoElement.player;
        if (player) {
            player.dispose();
            videoElement.player = null;
        }
    });
}


function initSlideshows(){

    $('.slideshow:not(.flickity-enabled)').each(function(){   

        let $this = $(this);

        let isSlideshow = $this.find('.news-entry').length > 3 ? true : false;

        let isRes = isResponsive();

        $this.flickity({
            cellAlign: 'left',
            contain: !isSlideshow,
            //groupCells: false,
            prevNextButtons: true,
            pageDots: true,
            wrapAround: isSlideshow,
            autoPlay: false,
            pauseAutoPlayOnHover: false,
            draggable: isRes,
            selectedAttraction: 0.013,
            friction: 0.26,
            dragThreshold: 20,
            adaptiveHeight: isRes
            //imagesLoaded:true
        });



        

		const flkty = $this.data('flickity');

        flkty.on('staticClick', function( event, pointer, cellElement, cellIndex ){

			if( !cellElement ){ return; }

			event.preventDefault();

			var $slide = $(cellElement);
			
			if( !$slide.hasClass('external') ){

				let url = $slide.data('url');

				Livewire.navigate(url);

			}else{

				let url = $slide.attr('href');

				window.open(url, '_blank').focus();

			}


		});



        $this.find('.flickity-page-dot').attr('tabindex', '-1');

    });

    updateSlideshows();

}


function destroySlideshows(){
	
    $('.slideshow.flickity-enabled').each(function(){

        const flickityInstance = $(this).data('flickity');

        if (flickityInstance) {

            flickityInstance.destroy();

        }

        $(this).removeClass('flickity-enabled');

    });

}


function updateSlideshows(){
	
    $('.slideshow.flickity-enabled').each(function(){

        var flkty = $(this).data('flickity');
        if(!flkty) { return; }

        if(!isResponsive()){

            flkty.options.draggable = false;
            flkty.options.selectedAttraction = 0.013;
            flkty.options.friction = 0.26;
            flkty.options.adaptiveHeight = false;

        }else{

            flkty.options.draggable = true;
            flkty.options.selectedAttraction = 0.08;
            flkty.options.friction = 0.5;
            flkty.options.adaptiveHeight = true;

        }

        flkty.updateDraggable();

    });

}

function loadImages(){

	const lazyLoadImages = document.querySelectorAll("img.blurry");

    // Create a new IntersectionObserver instance
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                
                // Load the real image
                blurryImageLoad.load(img);

                // Stop observing this image
                observer.unobserve(img);
            }
        });
    });

    lazyLoadImages.forEach(img => {
        observer.observe(img);
    });

}



function scrollToSection(url, event, callbackFailure){

	var $target = $('.module[data-slug="'+ url +'"]').first();

	if( $('body').attr('id') == 'home' && $target.length ){

		if(event){ event.preventDefault() };

		let top = $target.offset().top;

		$('html,body').animate({
			scrollTop:top + 'px'
		},400);

	}else{

		if(callbackFailure){

			callbackFailure();

		}

	}

}

function updateLanguage(){

    var url = window.location.href;

    if( url.search('/en/') !== -1 ){
    	url = url.replace('/en/', '/de/');
    }else{
    	url = url.replace('/de/', '/en/');	
    }

    $('header .language').attr('href', url);

}


function initEverything(){

	initVideos();

	initSlideshows();

	loadImages();


	$(window).on('resize', setResponsive).trigger('resize');

	$(document).on('scroll.default', function(){

		var ST = $(document).scrollTop();

		var offset = parseInt($(window).height() / 4 * 2.5);
		var offset2 = parseInt($(window).height() / 4 * 2);

		$('.trans:not(.invisible)').each(function(){

			if( ST + offset >= $(this).offset().top ){

				$(this).addClass('ani');

			}else{

				//$(this).removeClass('ani');

			}

		});


		$('.module.style-13').each(function(){

			let top = $(this).offset().top;
			let height = $(this).innerHeight();

			let $target = $(this).find('.images .image.second').first();
			let $slider = $(this).find('.images .slider').first();

			//if( ST + offset2 >= top && ST + offset2 < top + height ){

				var rest = (((top + height) - (ST + offset2)) / height) * 100;
				rest = rest.clamp(0, 100);
				let restReversed = 100 - rest;

				$target.css('width', rest + '%');
				$slider.css('left', restReversed + '%');


			//}


		});

		if( ST <= 20 ){

			$('#socialmedia').removeClass('out');
			
		}else if( scrollLastTop >= ST ){

			scrollUpDistance += (scrollLastTop - ST);

			if (scrollUpDistance >= scrollThreshold) {
				$('#socialmedia').removeClass('out');
			}

		}else{

			$('#socialmedia').addClass('out');
			scrollUpDistance = 0;

		}

		scrollLastTop = ST;

	});

	
	$(document).on( 'click.moduleclick', '.module.style-8', function(){

		var height = $(this).innerHeight();

		$('body,html').animate({
			scrollTop: height + 'px'
		},400);

	});


	$(document).on( 'click.more', '.btn.more', function(){

		var $parent = $(this).closest('.module');

		if( !$parent.hasClass('open') ){

			$(this).html( $(this).data('less') );

			let height = $parent.find('.list-wrapper').innerHeight();
			$parent.find('.list-wrapper').css('height', height + 'px');

			$parent.addClass('open');

			$parent.find('.list .entry.invisible').each(function(index){

				$(this).find('.number, .text').css('transition-delay', (index * 200) + 'ms').css('webkit-transition-delay', (index * 200) + 'ms');
				$(this).find('.number, .text').css('animation-delay', (index * 200) + 'ms').css('webkit-animation-delay', (index * 200) + 'ms');

				$(this).find('.image, .diamond').css('transition-delay', (index * 200 + 400) + 'ms').css('webkit-transition-delay', (index * 200 + 400) + 'ms');
				$(this).find('.image, .diamond').css('animation-delay', (index * 200 + 400) + 'ms').css('webkit-animation-delay', (index * 200 + 400) + 'ms');

			});

			$parent.find('.list .entry.invisible').removeClass('invisible');

			let newHeight = $parent.find('.list').innerHeight();

			$parent.find('.list-wrapper').animate({
				height: newHeight + 'px'
			},400, function(){

				$parent.find('.list-wrapper').removeAttr('style');

				$parent.find('.list .entry').addClass('ani');

			});

		}else{

			$(this).html( $(this).data('more') );

			let height = $parent.find('.list-wrapper').innerHeight();
			$parent.find('.list-wrapper').css('height', height + 'px');

			$parent.removeClass('open');

			$parent.find('.list .entry:nth-child(n+4)').addClass('invisible');


			let newHeight = $parent.find('.list').innerHeight();

			$parent.find('.list-wrapper').animate({
				height: newHeight + 'px'
			},400);


			var newTop = $parent.find('.list .entry:not(.invisible)').last().offset().top;

			$('body,html').animate({
				scrollTop: newTop + 'px'
			},400);

		}

	});


	$(document).on('click.ondemand', '.responsive-video.on-demand', function(e){

		var container = $(".guideline a");

	    if(!container.is(e.target) && container.has(e.target).length === 0){

			var src = $(this).data('src');

			var html = '<iframe width="560" height="315" src="'+ src +'?rel=0&controls=1&showinfo=0&autoplay=1;" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe>';

			$(this).empty().html(html);

			$(this).removeClass('on-demand');

		}

	});


	$(document).on('click.logo', 'footer .logo', function(e){

		if( $('body').attr('id') == 'home' ){

			e.preventDefault();

			scrollToTop();

		}

	});


	$(document).on('click.languages', '.languages', function(e){

		e.preventDefault();

		let $this = $(this);

		
		if( $(this).hasClass('switch') ){

			$this.find('a').html('DE');

		}else{

			$this.find('a').html('EN');

		}

		$(this).toggleClass('switch');

		setTimeout(function(){

			var url = $this.find('a').first().attr('href');

			window.location = url;

		}, 400);

	});


	$(document).on('click.blender', '#blender', function(){

		closeMenu();

	});


	$(document).on('click.characters', '.module.style-14 .characters', function(){

		if( !isResponsive() ){

			let $this = $(this);

			let $module = $this.closest('.module');

			$this.css('pointer-events', 'none');

			selectCharacter($module, $module.find('.character.selected').index() + 1);

			setTimeout(function(){

				$this.removeAttr('style');

			},400);

		}

	});


	$(document).on('click.paginator', '.module.style-14 .paginator .dot', function(){

		let $module = $(this).closest('.module');

		gotoCharacter($module, $(this).index());

	});

	$(document).on('click.paginator', '.module.style-14 .btn-character.prev', function(){

		let $module = $(this).closest('.module');

		selectCharacter($module, $module.find('.character.selected').index() - 1);

	});

	$(document).on('click.paginator', '.module.style-14 .btn-character.next', function(){

		let $module = $(this).closest('.module');

		selectCharacter($module, $module.find('.character.selected').index() + 1);

	});


	$(".module.style-14 .characters").swipe({

        swipe: function(event, direction, distance, duration, fingerCount, fingerData) {

        	if( isResponsive() ){

	            if(direction == 'left'){

	            	let $module = $(this).closest('.module');

	            	selectCharacter($module, $module.find('.character.selected').index() - 1);

	            }else{

	            	let $module = $(this).closest('.module');

	            	selectCharacter($module, $module.find('.character.selected').index() + 1);

	            }

	        }

        },
        threshold: 0
    });
    

	$(document).on('click.news-entry', 'a.news-entry', function(e){

		e.preventDefault();

	});

	$(document).on('click.mobile', '#mobile', function(){

		if( $('header').hasClass('open') ){
			closeMenu();
		}else{
			openMenu();
		}

	});

	$(document).on('click', '.btn-gdpr', function(){

		initGDPR(true);

	});
}


function deinitEverything(){

	destroyVideos();
	destroySlideshows();
	$(window).off();
	$(document).off();

	$(".module.style-14 .characters").swipe("destroy");

	$('.module.style-7').removeClass('open');
	$('.module.style-7 .list-wrapper').removeAttr('style');
	$('.module.style-7 .list .entry:nth-child(n+4)').addClass('invisible');

	$('.module.style-7 .btn.more').each(function(){

		$(this).html( $(this).data('more') );

	});

}



// Characters

function getCharacter($module, index){

	var i = index;

	let amount = $module.find('.character').length;

	if( i >= amount ){

		i -= amount;

	}

	let $character = $module.find('.character').eq(i);

	return $character;

}

function selectCharacter($module, index){

	$module.find('.character').removeClass('prev selected next').addClass('invisible');

	getCharacter($module, index + 1).addClass('prev').removeClass('invisible');
	getCharacter($module, index).addClass('selected').removeClass('invisible');
	getCharacter($module, index - 1).addClass('next').removeClass('invisible');

	var i = index;
	let amount = $module.find('.paginator .dot').length;

	if( i >= amount - 1 ){
		i = index - amount;
	}

	$module.find('.paginator .dot').removeClass('selected');
	$module.find('.paginator .dot').eq(i).addClass('selected');

}

function gotoCharacter($module, index){

	let i = $module.find('.character.selected').index();

	if( i != index ){

		$module.addClass('disabled');

		let nextIndex = i < index ? i + 1 : i - 1;

		selectCharacter($module, nextIndex);

		setTimeout(function(){

			gotoCharacter($module, index);

		},400);

	}else{

		$module.removeClass('disabled');

	}

}



function closeGDPR(reload){

	if(reload == true){

		location.reload();	

	}else{

		//$('#gdpr').fadeOut(400, function(){

			$('#gdpr').removeClass('trans');

			$('#gdpr').off('click.save').off('click.selectall');

		//});

	}

}

function initGDPR(reload = false){

 	var gdprAgreed = Cookies.get('gdprAgreed');

	if(!gdprAgreed || gdprAgreed == undefined || reload == true){

		$('#gdpr').on('click.save', '.button.save', function(){

			Cookies.set('gdprAgreed', 'true');
			/*
			_paq.push([function() {

		     	if( this.hasRememberedConsent() ){

		     		_paq.push(['forgetConsentGiven']);

		     	}

		    }]);*/

		    _paq.push(['forgetConsentGiven']);

			closeGDPR(reload);

		});

		$('#gdpr').on('click.selectall', '.button.selectall', function(){

			Cookies.set('gdprAgreed', 'true');

			_paq.push(['rememberConsentGiven']);

			closeGDPR(reload);    		

		});

		$('#gdpr').addClass('trans');

	}
	

}


$(document).ready(function(){

	initEverything();

	// will start
	document.addEventListener('livewire:navigate', (e) => {

		let url = e.detail.url.pathname.split("/");

		if( $('body').attr('id') == 'home' && url.length == 2 ){

			e.preventDefault();

			scrollToTop();

		}else{

			scrollToSection(url.pop(), e, function(){

				$('main').addClass('fadeout');		

				setTimeout(function(){

					deinitEverything();

				}, 200);

			});

		}

		closeMenu();
        
    });


	// before DOM change
	document.addEventListener('livewire:navigating', () => {

		

	});


	// completed
	document.addEventListener('livewire:navigated', () => {

		setTimeout(function(){

			initEverything();

			$(document).trigger('scroll.default');

			let url = window.location.href.split("/").pop();

			scrollToSection(url);
			
			updateLanguage();

			$('main').removeClass('fadeout');

			$('#preloader').removeClass('visible');

		}, 200);
		
		

	});


	// start	
	$('.module:first-child').imagesLoaded(function(){

		$(document).trigger('scroll.default');

		setTimeout(function(){

			$('main').removeClass('fadeout');

			$('#preloader').removeClass('visible');

			let url = window.location.href.split("/").pop();

			scrollToSection(url);

			initGDPR();

		},400);

	});


});



